import http from '@/api/request'

export function getHearder () {
  return http().get('/base/api/page/web/nav')
}

export function getFooter () {
  return http().get('/base/api/page/web/footer')
}

export function getComponentList () {
  return http().get('/base/api/page/web/list')
}

export function getComponentData (params) {
  return http().get('/base/api/page/web/detail', { params })
}

export function getSuspendData () {
  return http().get('/base/api/page/web/suspend')
}
